import { IClient } from "@/interfaces/IClient";
import { BasePledge } from "@/models/BasePledge";
import { BankDetailsService } from "@/services/bankDetailsService";
import store from "@/store";

export class AustraliaPledge extends BasePledge {
  isPoBox: boolean;
  poBox: string;
  unit: string;
  streetNumber: string;
  street: string;
  suburb: string;
  postcode: string;
  state: string;
  bsb: string;
  accountNumber: string;
  accountName: string;

  agreedToStripeDDR: boolean;
  stripeToken: string;

  constructor(fundraiserId: number, clientId: number, locationId: number, bookingId: number) {
    super(fundraiserId, clientId, locationId, bookingId);

    this.country = "Australia";
    this.isPoBox = false;
    this.poBox = "";
    this.unit = "";
    this.streetNumber = "";
    this.street = "";
    this.suburb = "";
    this.postcode = "";
    this.state = "";
    this.bsb = "";
    this.accountNumber = "";
    this.accountName = "";

    this.agreedToStripeDDR = false;
    this.stripeToken = "";
  }

  get formattedStreet() {
    let result = "";

    if (this.isPoBox) {
      result = this.poBox;
    } else {
      if (this.unit && this.streetNumber) {
        result = `${this.unit}/${this.streetNumber} ${this.street}`;
      } else if (this.streetNumber) {
        result = `${this.streetNumber} ${this.street}`;
      } else {
        result = this.street;
      }
    }

    return result;
  }

  clearDirectDebitDetails(): void {
    this.bsb = "";
    this.accountName = "";
    this.accountNumber = "";
  }

  /**
   * Validate Australian pledge contact details.
   * @returns True if pledge contact details are valid.
   */
  validateContactDetails(): Record<string, { message: string }> {
    let validationErros = {} as Record<string, { message: string }>;

    if (process.env.NODE_ENV == "development" && (window as any).klapaucius) {
      console.log("Skipping validation u cheater:", (window as any).klapaucius);
      return validationErros;
    }

    //pobox
    if (this.isPoBox && !this.poBox) validationErros["poBox"] = { message: "PO Box required" };
    else if (this.isPoBox && this.poBox.length > 250)
      validationErros["poBox"] = { message: "Max PO Box length is 500 characters" };

    //unit
    if (!this.isPoBox && this.unit) {
      if (this.unit.length > 10) {
        validationErros["unit"] = { message: "Max unit length is 10 characters" };
      }
    }

    //streetNumber
    if (!this.isPoBox && !this.streetNumber) validationErros["streetNumber"] = { message: "Street Number required" };
    else if (!this.isPoBox && this.streetNumber.length > 10)
      validationErros["streetNumber"] = { message: "Max street number lenght is 10 characters" };

    //street
    if (!this.isPoBox && !this.street) validationErros["street"] = { message: "Street required" };
    else if (!this.isPoBox && this.street.length > 250)
      validationErros["street"] = { message: "Max street lenght is 10 characters" };

    //suburb
    if (!this.suburb) validationErros["suburb"] = { message: "Suburb required" };
    else if (this.suburb.length > 100) validationErros["suburb"] = { message: "Max suburb lenght is 100 characters" };

    //postcode
    if (!this.postcode) validationErros["postcode"] = { message: "Postcode required" };
    else if (this.postcode.length != 4) validationErros["postcode"] = { message: "4 digits required" };
    // if (this.postcode < 1000 || this.postcode > 9999) return false; // -- think better about the postcode

    //state
    if (!this.state) validationErros["state"] = { message: "State required" };
    else if (this.state.length > 40) validationErros["state"] = { message: "Max state lenght is 40 characters" };

    //country
    const countryValidationErros = this.validateCountry();

    validationErros = { ...validationErros, ...countryValidationErros };

    //phone numbers
    const phoneValidationErros = this.validatePhoneNumbers();

    validationErros = { ...validationErros, ...phoneValidationErros };

    //email
    const emailValidationErros = this.validateEmail();

    validationErros = { ...validationErros, ...emailValidationErros };

    return validationErros;
  }

  /**
   * Validate Australian direct debit details.
   * @returns True if pledge direct debit details are valid.
   */
  async validateDirectDebitDetails(bankService: BankDetailsService): Promise<Record<string, { message: string }>> {
    const validationErros = {} as Record<string, { message: string }>;

    if (process.env.NODE_ENV == "development" && (window as any).klapaucius) {
      console.log("Skipping validation u cheater:", (window as any).klapaucius);
      return validationErros;
    }

    //bsb
    if (!this.bsb) validationErros["bsb"] = { message: "BSB required" };
    else if (this.bsb && this.bsb.length != 6) validationErros["bsb"] = { message: "BSB must have 6 digits" };
    else if (location.host.startsWith("test-") && this.bsb === "110000" && this.accountNumber === "000123456") {
      console.log("test bsb");
    } else if (!(await bankService.validateAustralianBsb(this.bsb))) {
      validationErros["bsb"] = { message: "Invalid BSB" };
    }

    //account number
    if (!this.accountNumber) validationErros["accountNumber"] = { message: "Account number required" };
    else if (this.accountNumber && (this.accountNumber.length < 6 || this.accountNumber.length > 10))
      validationErros["accountNumber"] = { message: "Account number must have between 6 and 10 digits" };

    //account name
    if (!this.accountName) validationErros["accountName"] = { message: "Account name required" };
    else if (this.accountName && this.accountName.length > 50)
      validationErros["accountName"] = { message: "Max account name lenght is 50 characters" };

    // authorized signator reason
    if (
      this.accountName &&
      this.accountName != (this.firstName + " " + this.lastName).trim() &&
      !this.authorizedSignatorReason
    )
      validationErros["authorizedSignatorReason"] = { message: "Reason required" };

    if ((store.getters.selectedClient as IClient).isStripeDD && !this.agreedToStripeDDR) {
      validationErros["ddrAgreed"] = { message: "You must agree to the DDR service agreement" };
    }

    return validationErros;
  }

  clearAddressDetails() {
    this.poBox = "";
    this.unit = "";
    this.streetNumber = "";
    this.street = "";
    this.suburb = "";
    this.postcode = "";
    this.state = "";
  }
}
